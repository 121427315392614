import router from '@/router';
import store from '@/store';
import axios, { AxiosRequestConfig } from 'axios';

console.log(process.env);

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? 'https://api-dev-nutricao.nortox.com.br'
      : 'https://api-dev-nutricao.nortox.com.br',
      // ? 'https://api-dev-nutricao.nortox.com.br'
      // : 'https://api-dev-nutricao.nortox.com.br',
  withCredentials: true,
  headers: { Accept: 'application/json' },
});

api.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem('token');

  if (config.headers === undefined) {
    config.headers = { Authorization: `Bearer ${token}` };
  } else {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch('logout');
      router.push('/autenticacao/login');
    } else {
      console.log(error.reponse);
    }

    return Promise.reject(error);
  }
);

api.get('/sanctum/csrf-cookie');

export { api };
